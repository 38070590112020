import React,{useState,useEffect} from 'react'
import {connect} from 'react-redux'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay';
import {AgarrarPrecios,ActualizarPrecios,AgarrarDisponibilidad,ActualizarDisponibilidad} from '../../Funciones/bookingEngine'
import {Button} from 'semantic-ui-react'
import { Menu,Segment } from 'semantic-ui-react'
import setRoomType from './../../redux/actions/setRoomType'
import './precioDormis.css'

import setListaPrecios from '../../redux/actions/setListaPrecios'
moment.locale('es')

export const ActualizarDisponibilidada=async(fecha,roomId,setListaDisponibilidad,setLoading)=>{


  setLoading(true);
  let primerDia=moment(fecha);
  let ultimoDia=moment(fecha).add(30,"days");
  let lista=[]
  const disponibilidad=await AgarrarDisponibilidad(primerDia,ultimoDia)
  const dias= ultimoDia.diff(primerDia,'days')
  for (let index = 0; index < dias; index++) {
    const fechaFormateada=primerDia.format('YYYY-MM-DD');
    const obj={fecha:fechaFormateada,disponibilidad:disponibilidad[roomId][fechaFormateada]}
    lista=[...lista,obj]
    primerDia.add(1,'days')
  }
  setListaDisponibilidad(lista)
 }
  //Aca se trae la lista de listas, se le pasa como parametro las rooms hijas de la habitacion (priceList)
  export const ActualizarListaDeLista=async(fecha,priceList,setListaDeListaDePrecios,setLoading)=>{
    setLoading(true);
    if (priceList.length==0){
      return []
    }
    let primerDia=moment(fecha);
    let ultimoDia=moment(fecha).add(30,"days");
    let listaDeLista=[];
    const precios=await AgarrarPrecios(primerDia.unix(),ultimoDia.unix())

    const dias=ultimoDia.diff(primerDia,'days')
    for (var price of priceList){
      let listaObj=[]
      for (let index = 0; index < dias; index++) {
        let precio={}
        //Si alguno de los dos es undefined
        if(!precios.prices[price.id]||!precios.prices[price.id][primerDia.format('YYYY-MM-DD')]){
          precio={price:0,roomtype_id:price.id};
        }
        else{
          precio=precios.prices[price.id][primerDia.format('YYYY-MM-DD')];
        }
        precio.fecha=primerDia.format("YYYY-MM-DD")
        listaObj=[...listaObj,precio]
        primerDia.add(1,'days')
      }
      listaObj.forEach(x=>{delete x.day;x.precio=x.price})
      const obj={name:price.name,adults:price.adults,list:listaObj,roomtype_id:price.id}
      listaDeLista=[...listaDeLista,obj]
      primerDia=moment(fecha);
    }
    setListaDeListaDePrecios(listaDeLista)  
    setLoading(false);
  }


  const Input = ({precio,fecha,roomId,setLoading,dispatch}) => {
      const [precioAux,setPrecioAux]=useState("")
      useEffect(()=>{
        setPrecioAux(precio)
      },[precio])
      return (
          <React.Fragment>
              <div className="rna-grid__cell rna-value-wrapper js-rna-cell js-drag-and-select rna-grid__cell--actionable " data-date="2019-11-06" >                    
                  <input className="rna-input rna-input--hidden" type="number" value={precioAux} onChange={(e)=>{setPrecioAux(e.target.value)}} onKeyPress={(event)=> {if(event.key === 'Enter'){setLoading(true);ActualizarPrecios(fecha,moment(fecha).add(1,"days").format("YYYY-MM-DD"),event.target.value,roomId,setLoading,dispatch)}}}  step="any"  style={{ bottom: 0, top: "auto", textAlign: "right", zIndex: 4}}/>
                  <span className="rna-value rna-value--bottom rna-value--caption " >
                      <div className="rna-value--currency">
                          AR$
                      </div>
                      {precio}
                  </span>
              </div>
          </React.Fragment>
      );
  };
  
  const MesYAno = (props) => {
      return (
          <React.Fragment>
               <div className="rna-title--headline" style={{borderRight:"0px",padding:"0.5rem"}}>{props.texto}</div>
          </React.Fragment>
      );
  };
  
  const Dia = ({texto,style}) => {
      return (
          <React.Fragment>
              <div  className="rna-grid__cell rna-value-wrapper js-drag-and-select js-rna-cell rna-grid__cell--actionable " data-date="2019-10-07">
                              {/* <input className="rna-input js-rna-cell-input rna-input--hidden" id="rna-rts-input-55835201-2019-10-07" defaultValue={1} data-at-type="rts" data-at-room-id={55835201} data-at-date="2019-10-07" data-on-click="selectInput(event)" data-preserve-el-when-focused type="number" min={0} step={1} data-on-keyup="handleSubmitOrCancelRTS('55835201', null, '2019-10-07', event)" data-on-blur="updateRoomsToSell('55835201', null, '2019-10-07', event)" /> */}
                              <span style={style} className="rna-value rna-value--bottom">
                                  {texto}
                              </span>
              </div>
          </React.Fragment>
      );
  };
  const Disponibilidad = ({disponibilidad,fecha,setLoading,roomId,dispatch}) => {
      return (
          <React.Fragment>
            <div className="rna-grid__cell js-rna-cell rna-value-wrapper "  data-date="2019-10-07">
             <span id="rna-55835201-2019-10-07-label"  onClick={()=>{setLoading(true);ActualizarDisponibilidad(fecha,moment(fecha).add(1,"days"),disponibilidad?0:1,roomId,setLoading,dispatch)}} style={!disponibilidad?{backgroundColor:"#e8bebe"}:{}} className={`rna-label rna-label--primary  rna-label--positive rna-label--actionable ${disponibilidad}`} data-on-click="toggleRoomStatus('55835201', '2019-10-07', event)">
             &nbsp;
             </span>
               </div>
          </React.Fragment>
      );
  };
  const Buscador= ({setLoading,priceList,setListaDeListaDePrecios,setListaDisponibilidad,roomTypeId})=>{
    var listaMeses=[]
    for (let index = 0; index < 15; index++) {
      listaMeses=[...listaMeses,moment().startOf('month').add(index,"month")];
    }
  
    return (
    <div className="row">
      <div className="col-md-3">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Mes</h4>
        <select className="form-control" id="exampleFormControlSelect1" onChange={(event)=>{ActualizarDisponibilidada(event.target.value,roomTypeId,setListaDisponibilidad,setLoading);ActualizarListaDeLista(event.target.value,priceList,setListaDeListaDePrecios,setLoading)}}>
        {listaMeses.map((x,i)=><option value={x.format("YYYY-MM-DD")} key={i}>{x.format("MMMM YYYY")}</option>)}
      </select>
      </div>
  </div>
    )
  }
  const RangoFechas= ({fecha,priceList,setLoading,dispatch})=>{
    const entrada= React.useRef([]);
    const salida= React.useRef([]);
    const dormi= React.useRef([]);
    const precio= React.useRef([]);

    const map={}
    priceList.forEach(x=>map[x.name]=x.id)
    return (
    <div className="row">
      <br/>
      <div className="col-md-2">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Desde</h4>
        <input type="date" ref={entrada} defaultValue={moment().format("YYYY-MM-DD")} className="form-control date-picker text-box single-line" id="desdeDate" name="desdeDate"  />
      </div>
      <div className="col-md-2">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Hasta</h4>
        <input type="date" ref={salida} defaultValue={moment().add(1,'days').format("YYYY-MM-DD")} className="form-control date-picker text-box single-line" id="hastaDate" name="desdeDate"/>
      </div>
      <div className="col-md-1">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Tipo</h4>
      <select className="form-control" ref={dormi} id="exampleFormControlSelect1">
          {priceList.map(x=><option value={x.roomtype_id}>{x.name}</option>)}
      </select>
  </div>
      <div className="col-md-1">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Precio</h4>
        <input type="number" ref={precio} defaultValue={100} className="form-control date-picker text-box single-line" id="desdeDate" name="desdeDate"/>
      </div>
  <div className="col-md-2">
    <br></br>
    <Button basic color='blue' size={"large"} onClick={()=>{setLoading(true);ActualizarPrecios(entrada.current.value,salida.current.value,precio.current.value,map[dormi.current.value],setLoading,dispatch)}}>
          Modificar precios
    </Button>
    </div>
  </div>
    )
  }
  const RangoFechasDisponibilidad= ({dispatch,setLoading,roomId})=>{
    const entrada= React.useRef([]);
    const salida= React.useRef([]);
    const disponibilidad= React.useRef([]);
    return (
    <div className="row">
      <br/>
      <div className="col-md-2">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Desde</h4>
        <input type="date" ref={entrada} defaultValue={moment().format("YYYY-MM-DD")} className="form-control date-picker text-box single-line" id="desdeDate" name="desdeDate"  />
      </div>
      <div className="col-md-2">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Hasta</h4>
        <input type="date" ref={salida} defaultValue={moment().add(1,'days').format("YYYY-MM-DD")} className="form-control date-picker text-box single-line" id="hastaDate" name="desdeDate"/>
      </div>
      <div className="col-md-2">
      <h4 style={{textAlign:"left",marginBottom:"5px"}}>Disponibilidad</h4>
        <input type="number" ref={disponibilidad} defaultValue={1} className="form-control date-picker text-box single-line" id="desdeDate" name="desdeDate"/>
      </div>
  <div className="col-md-2">
    <br></br>
    <Button basic color='blue' size={"large"} onClick={()=>{setLoading(true);ActualizarDisponibilidad(entrada.current.value,salida.current.value,disponibilidad.current.value,roomId,setLoading,dispatch)}}>
          Modificar disponibilidad
    </Button>
    </div>
  </div>
    )
  }

//Menu de habitaciones
const MenuTipoHabitacion=({informacionHotel,isMobile,mostrarTipoHabitaciones,setMostrarTipoHabitaciones,dispatch})=>(
  <React.Fragment>
        {informacionHotel.roomTypes&&<Menu pointing={!isMobile} secondary={!isMobile} stackable>
        {informacionHotel.roomTypes.filter(x=>x.name).map((x,i)=>
            <React.Fragment key={i}>
                <Menu.Item 
                        name={x.name}
                        active={mostrarTipoHabitaciones === x.name}
                        onClick={()=>{setMostrarTipoHabitaciones(x.name);dispatch(setRoomType(x.id))}}
                    />        
            </React.Fragment>
            )}</Menu>}
 </React.Fragment>
)



//----------------------------------------------------------------------------------------------
  const Calendario = ({informacionHotel,dispatch,roomType,refrescarDispoYPrecios}) => {
      const [loading,setLoading] = useState(false);
      const [isMobile,setIsMobile] = useState(false);
      const [loadingAux,setLoadingAux] = useState(false);
      const [listaDeListaDePrecios,setListaDeListaDePrecios] = useState([]);
      const [listaDisponibilidad,setListaDisponibilidad] = useState([]);
      const [stepWizard,setStepWizard]=useState(null);
      const [mostrarTipoHabitaciones,setMostrarTipoHabitaciones]=useState(null)
      React.useEffect(() => {
        if  (informacionHotel.roomTypes&&informacionHotel.roomTypes.length){
          setMostrarTipoHabitaciones(informacionHotel.roomTypes[0].name);
        }

      }, [informacionHotel]);
      //Llama una vez a la api
      React.useEffect(() => {
        if(informacionHotel&&informacionHotel.roomTypes&&!loading)
        {
          const fechaActual=moment().format("YYYY-MM-DD");
          const roomTypeElegido=informacionHotel.roomTypes.find(x=>x.id==roomType);
          if  (roomType&&roomTypeElegido.priceList){
            ActualizarListaDeLista(fechaActual,roomTypeElegido.priceList,setListaDeListaDePrecios,setLoadingAux)
            ActualizarDisponibilidada(fechaActual,roomTypeElegido.id,setListaDisponibilidad,setLoadingAux)
          }

        }
       
      }, [informacionHotel,loading,roomType,refrescarDispoYPrecios]);
     
          const roomTypeElegido=informacionHotel.roomTypes?informacionHotel.roomTypes.find(x=>x.id==roomType):null;
          if  (!roomTypeElegido||!roomTypeElegido.priceList||!roomTypeElegido.priceList.length){
            return (
            <React.Fragment> 
                <MenuTipoHabitacion informacionHotel={informacionHotel} isMobile={isMobile} mostrarTipoHabitaciones={mostrarTipoHabitaciones} setMostrarTipoHabitaciones={setMostrarTipoHabitaciones} dispatch={dispatch}/>
                <br></br>
                <h1>No hay precios cargados para la habitacion</h1>
            </React.Fragment>)
          }
          return (
            <LoadingOverlay
            active={loading}
            spinner
            text='Actualizando'
            >
            <LoadingOverlay
            active={loadingAux}
            spinner
            text='Actualizando'
            >
                <MenuTipoHabitacion informacionHotel={informacionHotel} isMobile={isMobile} mostrarTipoHabitaciones={mostrarTipoHabitaciones} setMostrarTipoHabitaciones={setMostrarTipoHabitaciones} dispatch={dispatch}/>
                <div className="bordeado">
                  {informacionHotel&&informacionHotel.roomTypes&&<Buscador roomTypeId={roomTypeElegido.id} setListaDisponibilidad={setListaDisponibilidad} setLoading={setLoadingAux} priceList={roomTypeElegido.priceList} dispatch={dispatch} setListaDeListaDePrecios={setListaDeListaDePrecios}/>}
                  <br></br>
                  {informacionHotel&&informacionHotel.roomTypes&&<RangoFechasDisponibilidad setLoading={setLoading} roomId={roomType} dispatch={dispatch} setListaDeListaDePrecios={setListaDeListaDePrecios}/>}

                  <br></br>
                  {informacionHotel&&informacionHotel.roomTypes&&<RangoFechas setLoading={setLoading}  priceList={roomTypeElegido.priceList} dispatch={dispatch} setListaDeListaDePrecios={setListaDeListaDePrecios}/>}
              <div className="rna-grid js-scroll-sync " id="rna-grid" data-render-id="rna-matrix">
                <div className="rna-grid__row rna-grid__row--full rna-room-row rna-stick-to-left js-observe-visibility js-rna-room-name-row" data-room-id={55835201} data-render-id="rna-room-block" id="room-name-55835201" style={{border:"0px"}}>
                  <div className="rna-grid__cell rna-grid__cell--first">
                    <div className="rna-room-row-items">
                      <div id="room_55835201" />
                      <div className="rna-room__align--start-end">
                        <div className="rna-room__name">
                        
                        </div>
                        <div className="rna-room__align--baseline bui-spacer--medium">
                          {/* <div className="rna-room__action-bulk_button">
                            <button className="bui-button bui-button--primary js-general-modal-trigger" type="button" data-room-id={55835201} data-from-date="2019-10-07" data-through-date="2019-11-06"><span className="bui-button__text">Editar en bloque</span></button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="rna-grid__row " data-room-id={55835201} id="net-booked-55835201" data-render-id="rna-room-block" style={{ border: "0px" }}>
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left rna-title" style={{ border: "0px" }}>
                    </div>
                    {
                      (listaDeListaDePrecios[0]) ? listaDeListaDePrecios[0].list.map((x, i) => <MesYAno texto={i == 0 ? moment(x.fecha).format("MMMM").toUpperCase() : i == 1 ? moment(x.fecha).format("YYYY") : ""} key={i} />) : ""
                    }
    
                  </div>
                  <div className="rna-grid__row js-rna-bulk-form-row js-onboarding-rooms" data-room-id={55835201} data-render-id="rna-room-block" data-form-type="rooms_to_sell" id="rooms-to-sell-55835201">
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                      <div className="rna-title">
                        Dia
                              </div>
                    </div>
                    {
                      (listaDeListaDePrecios[0]) ? listaDeListaDePrecios[0].list.map((x, i) => <Dia texto={moment(x.fecha).format("Do")} key={i} />) : ""
                    }
                  </div>

                  <div className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status " data-room-id={55835201} data-render-id="rna-room-block" data-form-type="room_status" id="room-status-55835201">
                    
                    <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left rna-title">
                      Estado de la habitación
                    </div>
                    {
                       (listaDisponibilidad.length>0) ? listaDisponibilidad.map((x,i)=><Disponibilidad  disponibilidad={x.disponibilidad} fecha={x.fecha} setLoading={setLoading} roomId={roomType} dispatch={dispatch} key={i}/>):""
                    }
                    </div>

                  <div className="rna-grid__row js-rna-bulk-form-row js-onboarding-room-status " data-room-id={55835201} data-render-id="rna-room-block" data-form-type="room_status" id="room-status-55835201">
                    
                  <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">
                      <div className="rna-title">
                      Habitaciones a la venta
                              </div>
                    </div>
                    {
                      (listaDisponibilidad.length>0) ? listaDisponibilidad.map((x, i) => <Dia style={{textAlign:"center",fontSize:16,fontWeight:700}} fecha={x.fecha} texto={x.disponibilidad} key={i} />) : ""
                    }
                  </div>
            {listaDeListaDePrecios.map((x,i)=>
            
              <React.Fragment>
            <div className="rna-grid__row js-onboarding-prices js-rna-bulk-form-row js-observe-visibility" data-room-id={55835201} data-render-id="rna-room-block" data-rate-id={1799663} data-occupancy={2} id="rate-status-55835201-1799663" data-form-type="rates">
              <div className="rna-grid__cell rna-grid__cell--first rna-grid__cell--wide rna-stick-to-left">

                <span className="rna-titlerna-title--expandable bui-link bui-link--primary" title="ID de tarifa: 1799663" data-on-click="toggleRateCategory('55835201','1799663',null,0,0)">
                  {x.name}
                  <span className="rna-title--expandable-icon">
                    <svg fill="#0077CC" size="medium" width={16} height={16} viewBox="0 0 128 128" className="bk-icon -iconset-navarrow_right"><path d="M54.3 96a4 4 0 0 1-2.8-6.8L76.7 64 51.5 38.8a4 4 0 0 1 5.7-5.6L88 64 57.2 94.8a4 4 0 0 1-2.9 1.2z" /></svg>
                  </span>
                </span>

                <span className="rna-title rna-title--secondary">

                    <span className="rna-occupancy-badge"><svg className="bk-icon -material-ic_person rna-occupancy-badge__icon" size="medium" width={16} height={16} viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg>
                      x {x.adults}</span>
              
                </span>
                                  </div>
                                  
                                  {
                                    
                                    x.list?x.list.map((x,i)=><Input dispatch={dispatch} setLoading={setLoading} precio={x.precio} key={x.fecha} fecha={x.fecha} roomId={x.roomtype_id} />):""
                                  }
                          
            </div>
              </React.Fragment>
              )}
                  </div>
                </div>
          </LoadingOverlay>
        </LoadingOverlay>
          );
      };
  

  export default connect(state=>({informacionHotel:state.dormis.informacionHotel,roomType:state.dormis.roomType,refrescarDispoYPrecios:state.dormis.refrescarDispoYPrecios}),null)(Calendario)
