import React,{useState,useEffect} from "react"
import ReactTable from 'react-table-6'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay';
import { Col,Row,Input,InputGroupText,InputGroup,InputGroupAddon,Container} from 'reactstrap';
import {AgregarServicios,DevolverComponentes} from "../../../Funciones/utils"
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import  Pagos  from './pagos'
import { Button} from 'semantic-ui-react'


export const Caja = ({informacionHotel,dispatch,reserva:ingreso,listaPrecios,isMobile}) => {
  const [tipo,setTipo]=useState("noches")
  const [quantity,setQuantity]=useState(1)
  const [costo,setCosto]=useState(0)
  const [precio,setPrecio]=useState(0)
  const [descripcion,setDescripcion]=useState("")
  const [componentes,setComponentes]=useState()
  const [descripcionPrecios,setDescripcionPrecios]=useState("")
  const [loading,setLoading] =useState(false);
  const [service,setService] =useState(null);
    useEffect(()=>{
      if(ingreso.servicesList.length==0){
        setQuantity(ingreso.nights)
        setTipo('noches')
      }

    },[ingreso])
    
    useEffect(()=>{
      setCosto(precio*quantity)
    },[precio])

    useEffect(()=>{
      if (quantity<0) {
        //alert("Las noches tienen que ser positivas")
        setQuantity(0)
      }
      if (tipo=="noches"||tipo=="") {
        setDescripcion(`${quantity} ${(quantity==1)?"noche":"noches"} ${descripcionPrecios}`)
      }
    },[quantity,ingreso,descripcionPrecios])
    useEffect(()=>{

      if (costo<0) {
        //alert("El importe tiene que ser positivo")
        setCosto(0)
      }

    },[costo])

    useEffect(()=>{
      if (listaPrecios){
        if (ingreso.servicesList.length==0){
          //El roomId de la habitacion elegida
          const roomId=ingreso.roomsList[0].roomId;
          const personas=ingreso.customersList.length
          const roomType=informacionHotel.roomTypes.find(x=>x.roomList.find(y=>y.roomId==roomId)).priceList.find(x=>x.adults==personas)??informacionHotel.roomTypes.find(x=>x.roomList.find(y=>y.roomId==roomId)).priceList[0];
          
          let precio=0;
          for (let index = 0; index < quantity; index++) {
            const fechaActual = moment(ingreso.checkinEstimated).add(index,'days').format('YYYY-MM-DD');
            if (listaPrecios.prices&&listaPrecios.prices[roomType.id]&&listaPrecios.prices[roomType.id][fechaActual]){
              precio+=parseInt(listaPrecios.prices[roomType.id][fechaActual].price);
            }
          
          }
          //Ponemos el precio de las noches en caso de que sea nueva reserva
          setCosto(precio)
        }
        else{
          const costAux=ingreso.servicesList[0].cost
          const quantityAux=ingreso.servicesList[0].quantity
          setCosto((costAux/quantityAux)*quantity)
        }

      }
    
     },[quantity,ingreso.checkoutEstimated,ingreso.checkinEstimated,ingreso.roomsList[0].room,informacionHotel,listaPrecios])

    const ReiniciarPagos=()=>{
     /* setTipo("Pago")
      setDescripcion("1 noche");
      setImporte(0)
      setForma("")
      setNoches(1)
      setMediaEstadia(false)
      setNumeroHoja(false)
      setCondicion("Contado")*/
    }


    var paymentColumn = [
    {
    Header: '',
    accessor: 'date',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
   
    Cell: props =>(moment(props.value).format('DD/MM/YYYY - hh:mm a')),
    filterable: false
      },
    {
    Header: '',
    accessor: 'isRefund',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
  
    filterable: false,
    Cell: props =>(props.value?"Devolucion":"Pago"),
      },
    {
    Header: '',
    accessor: 'amount',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: '',
    accessor: 'method',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
 
    filterable: false
      },
    
    ]
    var serviceColumn = [
    {
    Header: 'Fecha de creacion',
    accessor: 'date',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
    
    Cell: props =>(moment(props.value).format('DD/MM - hh:mm a')),
    filterable: false
      },

    {
    Header: 'Descripcion',
    accessor: 'description',
    width:340,
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
    filterable: false,
    Cell: props =>(<div style={{textAlign:"center"}}>{props.value}</div>),
      },
    {
    Header: 'Valor',
    accessor: 'cost',
  
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: 'Saldo',
    accessor: 'due',
  
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: 'Pago/Devolucion',
   
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>(<div style={{cursor:"pointer"}}><Icon  onClick={()=>{setService(props.original)}} size='large' style={{marginLeft:"5px"}} name='plus square'/></div>),
    filterable: false
      },
    ]

  const hojaNumeroAux=parseInt(Math.random()*10000)*2;
  const tipos=["noches","otros"]
 return (
    <React.Fragment>
      <LoadingOverlay
      active={loading}
      spinner
      text='Agregando'
      >
          <Pagos setService={setService} service={service} loading={loading} setLoading={setLoading} />
        
          <Row>
            
            <Col xs={12} md={2}> 
                <h3>Tipo</h3><Input value={tipo} onChange={(e)=>{setTipo(e.target.value)}}
                type="select">{tipos.map((x,i)=>(<option key={i}>{x}</option>))}</Input>
              </Col>

              <Col xs={12} md={2}> 
                <h3>Cantidad</h3><Input value={quantity} type="number" onChange={(e)=>{setQuantity(e.target.value)}}
               />
              </Col>

            <Col xs={12} md={4} > 
              <h3>Descripcion</h3><Input  type="textarea" value={descripcion} onChange={(e)=>{setDescripcion(e.target.value)}}
              />
              </Col>

            <Col xs={12} md={4}> 
          
            <Row style={{marginBottom:"10px",marginTop:"10px"}}>
            <Col xs={5}> <h3>Valor</h3></Col>
           {/* <Col xs={7}> <h3 style={{float: "right"}}>${ingreso.servicesList[0].perNight} x noche</h3></Col>*/}
          </Row>

              <InputGroup>
              <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                  <Input type="number" style={{fontSize:"26px",fontWeight:800}} value={costo} onChange={(e)=>{setCosto(e.target.value)}}/>
              </InputGroup>
          </Col>
          </Row>
          <br></br>
          <Container>
            <Row>
              <Col style={{display: "contents"}}>
              <Button.Group vertical={isMobile} style={{margin: "auto"}} widths={ingreso.state=='confirmada'?'3':'2'}>
                <Button size='big' color='teal' fluid onClick={()=>{
                    if (tipo=="") {
                      alert("falta agregar el tipo de servicio")
                    }
                    else if (costo=="") {
                      alert("falta agregar el valor")
                    }
                    else{
                      setLoading(true)
                      AgregarServicios(ingreso.reservationId,{cost:costo,quantity,name:tipo,description:descripcion,components:JSON.stringify(componentes)},dispatch,setLoading,setService)
                    }
                    }}>Pagar Ahora</Button>
                {!isMobile&&<Button.Or text={"o"}/>}
                <Button size='big' color='grey' fluid onClick={()=>{
                    if (tipo=="") {
                      alert("falta agregar el tipo de servicio")
                    }
                    else if (costo=="") {
                      alert("falta agregar el valor")
                    }
                    else{
                      setLoading(true)
                      AgregarServicios(ingreso.reservationId,{cost:costo,quantity,name:tipo,description:descripcion,components:JSON.stringify(componentes)},dispatch,setLoading)
                    }
                    }}>Pagar Luego</Button>
                </Button.Group>
              </Col>
            </Row>
           </Container>
           <br/>

          <br/>
          <br/>
          <Row>
            {/*<Col xs={12} md={5}><h5>Ingreso: {moment(ingreso.fechaIngreso).format('dddd DD - hh:mm a')}</h5></Col>*/}
          </Row>
          <br/>
          <Row>
            <Col xs={12} md={{size:12}} >
            
            </Col>
          </Row>
          
          <br/>
            <ReactTable 
            
            defaultPageSize={5}
            noDataText={'No hay pagos'} 
            className="-striped -highlight" 
            filterable 
            loadingText={"Cargando..."} 
            
            loading={false} columns={serviceColumn} 
            SubComponent={row => {
              
              return (
                <ReactTable 
                defaultPageSize={7}
                noDataText={'No hay pagos'} 
                className="-striped -highlight" 
                filterable 
                showPagination= {false}
                
                loadingText={"Cargando..."}
                loading={false} columns={paymentColumn}
                data={row.original.paymentsList.sort(function(a, b){return moment(b.date).diff(moment(a.date))})} />
              )
            }}
            data={ingreso.servicesList.sort(function(a, b){return moment(b.date).diff(moment(a.date))})} />
            <br/>

            <Row>
              {/* <Col xs={4} >
                <h3 style={{textAlign:"center"}}>Noches pagas</h3>
              </Col>
             <Col xs={8}>
                <h3>{ingreso.nochesPagas}</h3>
          </Col>*/}
            </Row>
            <Row>
              <Col xs={4} >
                <h3>Total abonado ${ingreso.servicesList.map(x=>x.paid).reduce((a,b) => a + b, 0)}</h3>
              </Col>
              <Col xs={8}>
                <h4></h4>
              </Col>
            </Row>
        
            <Row>
              {/*<Col xs={4} >
                <h4 style={{textAlign:"center"}}>Total Cta corriente</h4>
            </Col>
              <Col xs={8}>
                <h4>${ingreso.listaPagos.filter(x=>x.condicion=="Cta/cte").map(x=>x.importe).reduce((a,b) => a + b, 0)}</h4>
              </Col>*/}
            </Row>
            </LoadingOverlay>
      </React.Fragment>
   
    )
}



export default connect(state=>({informacionHotel:state.dormis.informacionHotel,listaPrecios:state.dormis.listaPrecios,listaReservas:state.dormis.listaReservas,refrescarDispoYPrecios:state.dormis.refrescarDispoYPrecios,precios:state.dormis.precios,isMobile:state.dormis.isMobile}),null)(Caja)

