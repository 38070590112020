import React,{useState,useEffect} from 'react'
import {connect} from 'react-redux'
import PhoneInput from 'react-phone-input-2'
import { Col,Row,Input} from 'reactstrap';
import TablaHabitaciones from './tablaHabitaciones'
import TablaTipoPrecios from './tablaTipoPrecios'
import TablaTipoHabitaciones from './tablaTipoHabitaciones'
import { Menu,Segment } from 'semantic-ui-react'
import StepWizard from 'react-step-wizard';
import LoadingOverlay from 'react-loading-overlay';

const Configuracion=({dispatch,informacionHotel,isMobile})=>{
    const [nombre,setNombre]=useState()
    const [direccion,setDireccion]=useState()
    const [email,setEmail]=useState()
    const [telefono,setTelefono]=useState()
    const [mostrarTipoHabitaciones,setMostrarTipoHabitaciones]=useState()
    const [stepWizard,setStepWizard]=useState(null);
    const [loading,setLoading]=useState(false);

    useEffect(()=>{
        setNombre(informacionHotel.name)
        setEmail(informacionHotel.email)
        setTelefono(informacionHotel.phone)
        setDireccion(informacionHotel.address)
    },[]);
    useEffect(()=>{
        if (informacionHotel.roomTypes&&informacionHotel.roomTypes.length==1){
            setMostrarTipoHabitaciones(informacionHotel.roomTypes[0].name)
            if(stepWizard){
                stepWizard.goToStep(1)
            }
        }
        else if (informacionHotel.roomTypes&&informacionHotel.roomTypes.length>1){
            setMostrarTipoHabitaciones(informacionHotel.roomTypes[informacionHotel.roomTypes.length-2].name)
            if(stepWizard){
                stepWizard.goToStep(informacionHotel.roomTypes.length-1)
            }
        }
      
       
    },[loading,informacionHotel])
    return (
        <LoadingOverlay
    active={loading}
    spinner
    text='Actualizando'
    >
       { /*<Row>
        <Col xs={12} md={3} className={"textLeft"}> <h3>Nombre del hotel</h3><Input disabled value={nombre} onChange={(e)=>setNombre(e.target.value)} name="nombre" id={"nombre"}></Input></Col>
        <Col xs={12} md={3} className={"textLeft"}> <h3>Direccion</h3><Input disabled value={direccion} onChange={(e)=>setDireccion(e.target.value)} name="direccion" id={"direccion"}></Input></Col>
        <Col xs={12} md={3} className={"textLeft"}> <h3>Celular Notificaciones</h3><PhoneInput disabled country={'ar'} value={telefono}onChange={phone => setTelefono( phone )}/></Col>
        <Col xs={12} md={3} className={"textLeft"}> <h3>Email Notificaciones</h3><Input disabled value={email} onChange={(e)=>{setEmail(e.target.value)}} ></Input></Col>
        </Row>*/}
    <br></br>
    <Col xs={12} md={3} className={"textLeft"}> <h3>Tipo Habitaciones</h3></Col>
    {informacionHotel.roomTypes&&<Menu pointing={!isMobile} secondary={!isMobile} stackable>
        {informacionHotel.roomTypes.filter(x=>x.name).map((x,i)=>
            <React.Fragment key={i}>
                <Menu.Item 
                        name={x.name}
                        active={mostrarTipoHabitaciones === x.name}
                        onClick={()=>{setMostrarTipoHabitaciones(x.name);stepWizard.goToStep(i+1)}}
                    />        
            </React.Fragment>
            )}

        <Menu.Menu position='right'>
            <Menu.Item 
                    name={"Administrar Habitaciones"}
                    active={mostrarTipoHabitaciones === "Administrar Habitaciones"}
                    onClick={()=>{setMostrarTipoHabitaciones("Administrar Habitaciones");stepWizard.goToStep(informacionHotel.roomTypes.length)}}
                />      
             </Menu.Menu>
    </Menu>}
    <br></br>
    {informacionHotel.roomTypes&&<StepWizard instance={(e)=>setStepWizard(e)}>
    {informacionHotel.roomTypes.map((x,i)=>
 
      <Row>
            {x.configuracion?
             <Col xs={12} md={{offset:4,size:4}}>
                <TablaTipoHabitaciones listaTipoHabitaciones={informacionHotel.roomTypes.filter(x=>x.name)} setLoading={setLoading}/>
            </Col>
            : 
            <React.Fragment>
                <Col xs={12} md={{offset:2,size:3}}>
                        <TablaHabitaciones listaDeptos={x.roomList} setLoading={setLoading} type={x.name} parentId={x.id}/>
                    </Col>
                    <Col xs={12} md={{offset:1,size:3}}>
                        <TablaTipoPrecios listaPrecios={x.priceList} setLoading={setLoading} parentId={x.id}/>
                </Col>
            </React.Fragment>}
      </Row>

          )}
   
      </StepWizard>}
    
</LoadingOverlay>

    )
}

export default connect(state=>({informacionHotel:state.dormis.informacionHotel,isMobile:state.dormis.isMobile}),null)(Configuracion)
