import React,{useState,useEffect} from "react"
import LoadingOverlay from 'react-loading-overlay';
import {Modal, ModalHeader, ModalBody, Container,Col,Row} from 'reactstrap';
import {connect} from 'react-redux'
import StepWizard from 'react-step-wizard';
import Caja from './caja'
import Reserva from './reserva'
import { Menu } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { Icon,Form, TextArea } from 'semantic-ui-react'
import {CambiarEstadoReserva} from '../../../Funciones/utils'
import { Button,Header} from 'semantic-ui-react'
//Actions
import setModal from '../../../redux/actions/setModalReserva'

const ReiniciarModal=(dispatch,setActiveItem,stepWizard)=>{
  setActiveItem("pagos")
  stepWizard.goToStep(1)
  dispatch(setModal(false))
}
export const ModalAux = ({openModal,dispatch,reserva,isMobile}) => {
  const [nuevaReserva,setNuevaReserva]=useState(true);
  const [stepWizard,setStepWizard]=useState({});
  const [notas,setNotas]=useState("");
  const [loading,setLoading]=useState(false);
  const [activeItem,setActiveItem]=useState("reserva");
  useEffect(()=>{
    setNuevaReserva(reserva.customersList==undefined)
    setNotas(reserva.description)
    setActiveItem(reserva.customersList==undefined?"reserva":"pagos")
  },[reserva])
  
  return (
    <Modal style={{maxWidth:"1000px"}} size={"lg"} isOpen={openModal} toggle={()=>{ReiniciarModal(dispatch,setActiveItem,stepWizard);}} >
    <LoadingOverlay
    active={loading}
    spinner
    text='Agregando'
    >
    <ModalHeader style={{borderBottom:"none"}} toggle={()=>{ReiniciarModal(dispatch,setActiveItem,stepWizard);}}
    >
      {reserva.customersList!=undefined?`Cuenta de ${reserva.customersList[0].fullName}`:`Nuevo Registro`}
      {reserva.customersList&&
      <Header size='tiny'>({reserva.way})</Header>}
    </ModalHeader>
    <ModalBody>
    <Menu tabular={!isMobile} stackable>
    {!nuevaReserva&&<Menu.Item
          active={activeItem === 'pagos'}
          onClick={()=>{setActiveItem("pagos");stepWizard.goToStep(1)}}
        >Pagos<Icon size='large' style={{marginLeft:"5px"}} name='money bill alternate'/></Menu.Item>}
    <Menu.Item
          active={activeItem === 'reserva'}
          onClick={()=>{setActiveItem("reserva");stepWizard.goToStep(2)}}
        >Datos<Icon size='large' style={{marginLeft:"5px"}} name='address book'/></Menu.Item>
      {!nuevaReserva&&<Menu.Item
          active={activeItem === 'notas'}
          onClick={()=>{setActiveItem("notas");stepWizard.goToStep(3)}}
        >Notas<Icon size='large' style={{marginLeft:"5px"}} name='edit'/></Menu.Item>}
    </Menu>

      <StepWizard instance={(e)=>setStepWizard(e)}>
      {reserva.customersList&&<Caja reserva={reserva} ReiniciarModal={()=>{ReiniciarModal(dispatch,setActiveItem,stepWizard)}}/>}
          <Reserva reserva={reserva} nuevaReserva={nuevaReserva} goToPayment={()=>{setActiveItem("pagos");stepWizard.goToStep(1)}}/> 
          {reserva.customersList&& 
          <Form>
            <TextArea value={notas} onChange={(e)=>{setNotas(e.target.value)}} placeholder='Escribir notas..'/>
           
            <Container>
            <br></br>
            <Row>
              <Col style={{display: "contents"}}>
                <Button size='big' color='teal' fluid onClick={()=>{setLoading(true);reserva.description=notas;CambiarEstadoReserva(reserva,setLoading,()=>{ReiniciarModal(dispatch,setActiveItem,stepWizard);dispatch(setModal(false));},dispatch)}}>Guardar</Button></Col>
         </Row>
         </Container>
         </Form>}
      </StepWizard>
    </ModalBody>
    </LoadingOverlay>
  </Modal>
   
    )
}



export default connect(state=>({preReserva:state.dormis.preReserva,openModal:state.dormis.modal,reserva:state.dormis.reserva,isMobile:state.dormis.isMobile}),null)(ModalAux)

