import React, {useState,useRef, useEffect} from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import {connect} from 'react-redux'
import moment from 'moment'
import { Auth } from 'aws-amplify';
//import {TinyDatePicker} from 'tiny-date-picker/dist/date-range-picker'
import DateRangePicker from '../components/dormis/extras/dateRangePicker';
import Scheduler from '../components/dormis/main/scheduler';
import Modal from '../components/dormis/caja/modal'
import DisplayFechaModal from '../components/dormis/main/displayFecha'
import { Label,Button } from 'semantic-ui-react'
import {Col,Row} from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay';
import {ActualizarListaDeptos,actualizarListaReservas,SetSubscriptions,ActualizarDivisa} from '../Funciones/utils'
import {actualizarInformacionHotel,SetSession} from '../Funciones/bookingEngine'
import {DisplayFecha} from '../components/dormis/main/displayFecha'
import { Menu,Input,Segment } from 'semantic-ui-react'
import StepWizard from 'react-step-wizard';
import TablaRegistro from '../components/dormis/tablaRegistro'
import Calendario from '../components/dormis/precioDormis'
import Configuracion from '../components/dormis/configuracion/configuracion'
import setRoomType from '../redux/actions/setRoomType'
import setIsMobile from '../redux/actions/setIsMobile'
import {Collapse} from 'react-collapse';
import {AgarrarPrecios} from '../Funciones/bookingEngine'
import Estadisticas from '../components/dormis/estadisticas/main';

import setListaPrecios from '../redux/actions/setListaPrecios'
const ActualizarListaPrecios=async(dispatch,fechaInicio,cantidadDias)=>{
  await ActualizarDivisa(dispatch)
  await ActualizarPreciosRedux(dispatch,fechaInicio,moment(fechaInicio).add(cantidadDias,'days').format('YYYY-MM-DD'));
}
const EjecutarActualizacion=async (setLoading,dispatch)=>{
    const fechaInicio=moment().format('YYYY-MM-DD')
    const cantidadDias=90
    await SetSession(dispatch)
    ActualizarListaPrecios(dispatch,fechaInicio,cantidadDias)
    await ActualizarListaDeptos(()=>{},dispatch)
    await actualizarListaReservas(()=>{},dispatch)
    const informacionHotel=await actualizarInformacionHotel(setLoading,dispatch)
    if (informacionHotel.roomTypes[0].id) {
        dispatch(setRoomType(informacionHotel.roomTypes[0].id))
    }
    //Cada 55 min actualiza el token
    const refrescarToken=()=>{
      setTimeout(()=>{
      window.location.reload(false);
    },3300000)}
   refrescarToken();

}


//Actualiza la lista de precio de redux para ser usada por ejemplo cuando creamos una reserva
const ActualizarPreciosRedux=async (dispatch,fechaInicio,fechaFin)=>{
  const precios=await AgarrarPrecios(moment(fechaInicio).unix(),moment(fechaFin).unix())
  dispatch(setListaPrecios(precios))
}



const StepOne=({dispatch,fecha,listaReservas,setLoading})=>{
  const [fechaInicio,setFechaInicio]=useState(null)
  const [cantidadDias,setCantidadDias]=useState(90)
  const [mostrarReservas,setMostrarReservas]=useState("confirmadas")
  const [rangoFechasOpened,setRangoFechasOpened]=useState(false)
  const startRef=useRef();
  const endRef=useRef();
  useEffect(()=>{
    setFechaInicio(moment().format('YYYY-MM-DD'))
    const mobile=window.innerWidth<600
    dispatch(setIsMobile(mobile))
    /*fetch(`https://yxt7f6hso6.execute-api.us-east-1.amazonaws.com/DEV/demo?type=nuevo visitante&email=&mobile=${mobile}`)*/
    SetSubscriptions(dispatch);
    EjecutarActualizacion(setLoading,dispatch,fechaInicio,cantidadDias);
  },[])
  useEffect(()=>{
    ActualizarPreciosRedux(dispatch,fechaInicio,moment(fechaInicio).add(cantidadDias,'days').format('YYYY-MM-DD'),setLoading)
  },[fechaInicio])
  return(
  <React.Fragment>
  <Button onClick={()=>{setRangoFechasOpened(!rangoFechasOpened)}}>Rango de fechas</Button>
    <br></br>
    <Collapse isOpened={rangoFechasOpened}>
        <Col xs={12}>
          <DateRangePicker startRef={startRef} endRef={endRef}/>
          <Button basic color='blue' size={"big"} onClick={()=>{
            const inicioFixed=startRef.current.value.split("/").reverse().join("-")
            const salidaFixed=endRef.current.value.split("/").reverse().join("-")
            setFechaInicio(inicioFixed);
            ActualizarPreciosRedux(dispatch,inicioFixed,salidaFixed)
            setCantidadDias(moment(salidaFixed).diff(moment(inicioFixed),"days"))}}>
            Buscar
          </Button>
        </Col>
    </Collapse>
  
    <Menu pointing secondary >
      <Menu.Item
        name='Confirmadas'
        active={mostrarReservas === 'confirmadas'}
        onClick={()=>{setMostrarReservas("confirmadas")}}
      />
      <Menu.Item
        name='Canceladas'
        active={mostrarReservas === 'canceladas'}
        onClick={()=>{setMostrarReservas("canceladas")}}
      />
      <Menu.Item
        name='Ambas'
        active={mostrarReservas === 'ambas'}
        onClick={()=>{setMostrarReservas("ambas")}}
      />
    </Menu>
    <Row>
      <Col xs={12}>
      {mostrarReservas !== 'canceladas'&&
        <Label  color='blue' horizontal>
          Confirmada
        </Label>}
        {mostrarReservas !== 'canceladas'&&
        <Label  color='green' horizontal>
          Checkin
        </Label>}
        {mostrarReservas !== 'confirmadas'&&
        <Label  color='red' horizontal>
          Checkout
        </Label>}
        {mostrarReservas !== 'confirmadas'&&
         <Label  color='black' horizontal>
          Cancelada
        </Label>}
      </Col>
    </Row>
      {<Scheduler  disable={mostrarReservas!="confirmadas"} listaReservas={mostrarReservas=="confirmadas"?listaReservas.filter(x=>x.state!='cancelada'&&x.checkoutMade==null):mostrarReservas=='canceladas'?listaReservas.filter(x=>x.state=='cancelada'||x.checkoutMade!=null):listaReservas} setLoading={setLoading} fechaInicio={fechaInicio} cantidadDias={cantidadDias}/>}
      <Modal/>
      <DisplayFechaModal fecha={fecha} mostrarFull={true}/>
  </React.Fragment>
  )}

const Index=({dispatch,fecha,listaReservas,isMobile})=>{
  const [mainMenu,setMainMenu]=useState("calendario")
  const [stepWizard,setStepWizard]=useState({})
  const [loading,setLoading]=useState(true)
  const [session,setSession]=useState({})

  useEffect(()=>{
    Auth.currentSession().then(x=>{
      setSession(x);
    })
  },[])
  return(
    <React.Fragment>
      <LoadingOverlay
      active={loading}
      spinner
      text='Buscando'
      >
        <br></br>
        <Col xs={12}>
        <DisplayFecha fecha={moment().format()} listaReservas={listaReservas} dispatch={dispatch} mostrarFull={false}/>
        </Col>
        <br></br>
        <Menu pointing={!isMobile} stackable>
          <Menu.Item
            name='calendario'
            active={mainMenu === 'calendario'}
            onClick={()=>{stepWizard.goToStep(1);setMainMenu("calendario")}}
          />
          <Menu.Item
            name='listado'
            active={mainMenu === 'listado'}
            onClick={()=>{stepWizard.goToStep(2);setMainMenu("listado")}}
          />
          <Menu.Menu position='right'>
          {session?.accessToken?.payload["cognito:groups"].filter(x=>x=="superUser").length!=0&&<Menu.Item
            name='estadisticas'
            active={mainMenu === 'estadisticas'}
            onClick={()=>{stepWizard.goToStep(3);setMainMenu("estadisticas")}}
          />}
          <Menu.Item
            name='precios'
            active={mainMenu === 'precios'}
            onClick={()=>{stepWizard.goToStep(4);setMainMenu("precios")}}
          />
          <Menu.Item
            name='configuracion'
            active={mainMenu === 'configuracion'}
            onClick={()=>{stepWizard.goToStep(5);setMainMenu("configuracion")}}
          />
            {false&&<Menu.Item>
              <Input disabled icon='search' placeholder='Buscar reserva...' />
            </Menu.Item>}
            <Menu.Item
            name='cerrarSesion'
            active={mainMenu === 'cerrarSesion'}
            onClick={()=>{Auth.signOut()}}
          />
          </Menu.Menu>
        </Menu>
        
        <Segment>
          <StepWizard initialStep={1} instance={(e)=>setStepWizard(e) }>
            <StepOne dispatch={dispatch} listaReservas={listaReservas} fecha={fecha} setLoading={setLoading}/>
            <TablaRegistro dispatch={dispatch} listaReservas={listaReservas}/>
            <Estadisticas/>
            <Calendario/>
            <Configuracion/>
          </StepWizard>
          </Segment>
        </LoadingOverlay>
    </React.Fragment>
  )
}

const Wrapper=(props)=>(
  <Layout>
    <SEO title="Departamentos" />
    <Index {...props}></Index>
  </Layout>
)

export default connect(state=>({fecha:state.dormis.fecha,listaReservas:state.dormis.listaReservas,isMobile:state.dormis.isMobile}),null)(Wrapper)


