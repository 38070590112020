import React,{useState,useEffect} from 'react'
import ReactTable from 'react-table-6'
import moment from 'moment'
import {ElegirLabel} from '../../Funciones/utils'
import {connect} from 'react-redux'
import { Menu,Checkbox} from 'semantic-ui-react'

//ACTIONS
import setReserva from '../../redux/actions/setReserva'
import setModal from '../../redux/actions/setModalReserva'


const withCaja=(componente,dispatch,props)=><div className="test-WithCaja" style={{cursor:"pointer"}} onClick={()=>{dispatch(setModal(true));dispatch(setReserva(props.original))}}>{componente}</div>

const TablaRegistro=({listaReservas,dispatch,isMobile})=>{
  const [mostrarReservas,setMostrarReservas]=useState("adeudados")
  const [tamanoPantalla,setTamanoPantalla]=useState(1000)
  const [todasLasPersonas,setTodasLasPersonas]=useState(false)
  useEffect(()=>{
    setTamanoPantalla(window.innerWidth-100)
  },[])
  const timeFormat=isMobile?'DD/MM':'DD/MM/YYYY';
    var columns = [
        {
        Header: isMobile?'Creada':'Fecha Creada',
        accessor: 'created',
        
        sortMethod:(a, b, desc) => {
            if (moment(a).diff(moment(b),"minutes") > 0) {
              return 1
            }
            else{
                return -1
            }
          
          },
        Cell: props => withCaja(isMobile?moment(props.value).format(timeFormat).split('.').join(""):moment(props.value).format("DD MMM YYYY hh:mm").split('.').join(""),dispatch,props),
        filterable: false
          },
        {
        Header: isMobile?'Ingreso':'Fecha Ingreso',
        accessor: 'checkinEstimated',
        
        sortMethod:(a, b, desc) => {
            if (moment(a).diff(moment(b),"minutes") > 0) {
              return 1
            }
            else{
                return -1
            }
          
          },
        Cell: props => withCaja(isMobile?moment(props.value).format(timeFormat).split('.').join(""):moment(props.value).format(timeFormat).split('.').join(""),dispatch,props),
        filterable: false
          },{
        Header: isMobile?'Apellido':'Nombre y Apellido',
        id: 'fullName', 
        accessor: d => d.customersList[0].fullName, 
      
        Cell: props => withCaja(isMobile?props.value.split(" ")[0]:props.value,dispatch,props),
        filterMethod: (filter, rows) =>
                      rows.filter(x=>x.fullName.toLowerCase().includes(filter.value.toLowerCase())),
        filterAll: true// String-based value accessors!
        }, 
        {
            Header: 'Noches',
            accessor: 'nights',
          
            Cell: props => withCaja(props.value,dispatch,props),
            filterMethod: (filter, rows) =>
                          rows.filter(x=>x.nights==filter.value),
            filterAll: true// String-based value accessors!
            }, 
        {
            Header: 'Estado',
            accessor: 'state',
          
            Cell: props => withCaja(ElegirLabel(props.value),dispatch,props),
            filterMethod: (filter, rows) =>
                          rows.filter(x=>x.state.toLowerCase().includes(filter.value.toLowerCase())),
            filterAll: true// String-based value accessors!
            }, 
        {
            Header: 'Pagado',
            accessor: 'percentPayed',
          
            Cell: props => withCaja(props.value+"%",dispatch,props),
            filterMethod: (filter, rows) =>
                          rows.filter(x=>x.percentPayed==filter.value),
            filterAll: true// String-based value accessors!
            }, 
        {
            Header: 'Modo',
            accessor: 'way',
          
            Cell: props => withCaja(props.value,dispatch,props),
            filterMethod: (filter, rows) =>
                          rows.filter(x=>x.way.toLowerCase().includes(filter.value.toLowerCase())),
            filterAll: true// String-based value accessors!
            }, 
        {
          Header: 'DNI/PAS',
        
          id: 'dni', 
          accessor: d => d.customersList[0].dni, 
          Cell: props => withCaja(props.value!="null"?props.value:"-",dispatch,props),
          filterMethod: (filter, rows) =>
          rows.filter(x=>x.dni.toLowerCase().includes(filter.value.toLowerCase())),
          filterAll: true// String-based value accessors!
        },
        {
        Header: 'Vehiculos',
      
        Cell: props => withCaja(`${props.value.length>0?props.value.map(x=>(x.plate)).toString().replace(/,/g,' | '):"-"}`,dispatch,props),
        accessor: 'vehiclesList',
        filterMethod: (filter, rows) =>
        rows.filter(x=>x.vehiclesList.map(y=>(y.plate)).toString().toLowerCase().includes(filter.value.toLowerCase())),
        filterAll: true,
          },
        {
        Header: 'Departamento',
        accessor: 'roomsList',
        Cell: props => withCaja(props.value.map(x=>x.room).join(' '),dispatch,props),
        filterMethod: (filter, rows) =>
        rows.filter(x=>x.roomsList.map(x=>x.room).join(' ').toLowerCase().includes(filter.value.toLowerCase())),
        filterAll: true,
          },
        {
        Header: 'Salida',
      
        accessor: 'checkoutEstimated',
        Cell: props => withCaja(isMobile?moment(props.value).format(timeFormat).split('.').join(""):moment(props.value).add(3,"hours").format('dddd DD - hh:mm a'),dispatch,props),
        filterable: false
          }]

    let lista=todasLasPersonas?listaReservas.map(x=>x.customersList.map(y=>({...x,customersList:[y,...x.customersList.filter(x=>x!=y)]}))).flat():listaReservas

    lista=lista.sort((a, b) => moment(a.checkinEstimated).diff(moment(b.checkinEstimated)))
    lista=mostrarReservas=="alojados"?lista.filter(x=>x.checkinMade&&!x.checkoutMade&&x.state!="cancelada"):lista;
    lista=mostrarReservas=="adeudados"?lista.filter(x=>(x.percentPayed!=100||moment(x.checkoutEstimated).add(3,"hours").diff(moment(),'minutes')<0)&&x.state=="checkin"):lista;
    lista=mostrarReservas=="fllegar"?lista.filter(x=>x.checkinMade==null&&x.state!="cancelada"):lista;
    return(
    <React.Fragment>
        <h3>{todasLasPersonas?"Todas las personas":"Solo registros"} </h3>
      <Checkbox toggle checked={todasLasPersonas} onChange={(e)=>setTodasLasPersonas(!todasLasPersonas)}/>
      <Menu pointing={!isMobile} stackable secondary={!isMobile}>
      <Menu.Item
        name='Adeudados'
        active={mostrarReservas === 'adeudados'}
        onClick={()=>{setMostrarReservas("adeudados")}}
      />
      <Menu.Item
        name='Alojados'
        active={mostrarReservas === 'alojados'}
        onClick={()=>{setMostrarReservas("alojados")}}
      />
      <Menu.Item
        name='Reservas'
        active={mostrarReservas === 'fllegar'}
        onClick={()=>{setMostrarReservas("fllegar")}}
      />
      <Menu.Item
        name='Historico'
        active={mostrarReservas === 'todas'}
        onClick={()=>{setMostrarReservas("todas")}}
      />
    </Menu>
      
    <br></br>
    <ReactTable noDataText={'No se encontraron reservas'} className="-striped -highlight" filterable loadingText={"Cargando..."} loading={false} columns={columns} data={lista}></ReactTable>
    </React.Fragment>
    )
  }

export default connect(state=>({isMobile:state.dormis.isMobile}),null)(TablaRegistro)

